<script lang="ts" setup>
import { onMounted } from 'vue';

const { $gsap } = useNuxtApp();

const animate = () => {
  const matchMedia = $gsap.matchMedia();

  const selector = $gsap.utils.selector('#unnoticed-skills');

  matchMedia.add('(min-width: 768px)', () => {
    const mdTimeline = $gsap.timeline({
      scrollTrigger: {
        trigger: '#unnoticed-skills',
        start: 'top 60%',
        once: true,
      },
    });

    mdTimeline.fromTo(selector(`.title`), { opacity: 0, x: -10 }, { opacity: 1, x: 0, duration: 0.5 });
    mdTimeline.fromTo(selector(`.subtitle`), { opacity: 0, x: -10 }, { opacity: 1, x: 0, duration: 0.5 }, '<0.2');
    mdTimeline.fromTo(selector(`.cta-btn`), { opacity: 0, x: -10 }, { opacity: 1, x: 0, duration: 0.5 }, '<0.2');
    mdTimeline.fromTo(
      selector('.image img'),
      { opacity: 0, scale: 0.95 },
      {
        opacity: 1,
        scale: 1,
        duration: 0.5,
        delay: 0.5,
      },
      '<',
    );
  });
  matchMedia.add('(max-width: 767px)', () => {
    const smTimeline = $gsap.timeline({
      scrollTrigger: {
        trigger: selector('.title'),
        start: 'top 60%',
        once: true,
      },
    });

    smTimeline.fromTo(selector(`.title`), { opacity: 0, x: -10 }, { opacity: 1, x: 0, duration: 0.5 });
    smTimeline.fromTo(selector(`.subtitle`), { opacity: 0, x: -10 }, { opacity: 1, x: 0, duration: 0.5 }, '<0.2');
    smTimeline.fromTo(selector(`.cta-btn`), { opacity: 0, x: -10 }, { opacity: 1, x: 0, duration: 0.5 }, '<0.2');

    $gsap.fromTo(
      selector('.image img'),
      { opacity: 0, scale: 0.95 },
      {
        opacity: 1,
        scale: 1,
        duration: 0.5,
        delay: 0.5,
        scrollTrigger: {
          trigger: '.image',
          start: 'top 80%',
          once: true,
        },
      },
    );
  });
};

onMounted(() => {
  animate();
});

onUnmounted(() => {
  $gsap.killTweensOf('#unnoticed-skills *');
});
</script>

<template>
  <div id="unnoticed-skills" class="px-4">
    <div class="container flex flex-col md:flex-row gap-8 pt-16 md:pt-24">
      <div class="md:w-1/3 flex flex-col gap-8 md:pt-16 lg:pt-24 text-center md:text-left">
        <UIHeading class="title opacity-0" size="h2" styleSize="h2">
          {{ $t('landing.dontLetSkillsUnnoticed') }}
        </UIHeading>
        <p class="subtitle opacity-0">{{ $t('landing.youPortfolioGathersEverything') }}</p>
        <div class="cta-btn opacity-0">
          <UIButton :text="$t('landing.getStartedItsFree')" customClasses=" w-fit mx-auto md:mx-0 " to="/login" />
        </div>
      </div>
      <div class="image md:w-2/3 text-center">
        <NuxtImg class="w-full opacity-0" preload role="presentation" src="/images/landing/profile.webp" />
      </div>
    </div>
    <div class="flex justify-center">
      <UIButton
        :text="$t('landing.getStartedNowItsFree')"
        :to="{ name: 'plan-login' }"
        class="my-10 lg:my-0 lg:mb-10"
        customClasses="text-lg md:text-3xl hover:scale-110 transition-transform duration-500 shadow-xl w-fit"
        iconPath="circle-right-arrow"
        iconPosition="right"
        size="lg"
        variant="primary"
      />
    </div>
  </div>
</template>

<style scoped>
#unnoticed-skills {
  box-shadow: 0 20px 40px -10px rgba(0, 0, 0, 0.09) inset;
}
</style>
